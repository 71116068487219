import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const FloatingBar = ({ text, button, idItemToShow }) => {
    const handleFloatingBar = () => {
        let element, elementBounded = null
        if (idItemToShow) {
            // Getting the dynamic block
            element = document.getElementById(idItemToShow)
            elementBounded = element.getBoundingClientRect()
        }
        // Getting the footer
        const footer = document.querySelector('footer.footer')
        const footerElement = footer.getBoundingClientRect()
        // Getting the floating bar
        const bar = document.getElementsByClassName('floating-bar')[0]
        const barElement = bar.getBoundingClientRect()
        
        if (barElement.bottom >= footerElement.top || (elementBounded && barElement.bottom <= elementBounded.top)) {
            bar.classList.remove("show");
        } else  {
            bar.classList.add("show")
        }
    }

    const win = (typeof window !== "undefined" ? window : null)
    win?.addEventListener("scroll", handleFloatingBar)

    return (
        <div className={`floating-bar ${(idItemToShow ? '' : 'show')}`}>
            <div className="floating-bar__container">
                <div className="floating-bar__container__text">
                    <ReactMarkdown children={text} rehypePlugins={[rehypeRaw]} />
                </div>
                {button && (
                    <div className="floating-bar__container__buttons">
                        {button}
                    </div>
                )}
            </div>
        </div>
    )
}

export default FloatingBar